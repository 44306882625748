import {
  // eslint-disable-next-line no-restricted-imports
  Modal as AppBridgeModal,
  type ModalProps as AppBridgeModalProps,
  type TitleBarProps as AppBridgeTitleBarProps,
  TitleBar,
} from '@shopify/app-bridge-next-react';
import {
  // eslint-disable-next-line no-restricted-imports
  Modal as PolarisModal,
  _SECRET_INTERNAL_WITHIN_CONTENT_CONTEXT as WithinContentContext,
} from '@shopify/polaris-internal';
import React from 'react';

import {type MarkRequired} from '~/types';
import {
  type ActionButton,
  actionToButton,
} from '~/utils/actionToAppBridgeButton';

export type ModalProps = MarkRequired<AppBridgeModalProps, 'open' | 'onHide'> &
  MarkRequired<AppBridgeTitleBarProps, 'title'> & {
    primaryAction?: ActionButton;
    secondaryActions?: ActionButton[];
  };

export function Modal({
  children,
  variant,
  onHide,
  title,
  primaryAction,
  secondaryActions,
  ...rest
}: ModalProps) {
  const actions = [
    ...(primaryAction ? [{...primaryAction, variant: 'primary'} as const] : []),
    ...(secondaryActions ?? []),
  ];

  return (
    <AppBridgeModal {...rest} variant={variant} onHide={onHide}>
      {title || actions.length ? (
        <TitleBar title={title}>
          {actions.map(({content, ...rest}) => (
            <button key={content} type="button" {...actionToButton(rest)}>
              {content}
            </button>
          ))}
        </TitleBar>
      ) : null}
      <WithinContentContext.Provider value>
        {children}
      </WithinContentContext.Provider>
    </AppBridgeModal>
  );
}
// for ease of use - almost all uses will need to wrap their children in a section
Modal.Section = PolarisModal.Section;
