import type {
  DestructableAction,
  DisableableAction,
  LoadableAction,
} from '@shopify/polaris-internal';

export type ActionButton = DisableableAction &
  LoadableAction &
  DestructableAction;

export const actionToButton = ({
  onAction,
  loading,
  destructive,
  ...rest
}: ActionButton) => ({
  onClick: onAction,
  // Yes, this is the only possible value to get a loading state https://github.com/Shopify/app-ui/issues/1020
  loading: loading ? '' : undefined,
  tone: destructive ? ('critical' as const) : undefined,
  ...rest,
});
